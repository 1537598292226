"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Operation = void 0;
var Operation;
(function (Operation) {
    Operation["Connect"] = "connect";
    Operation["Logout"] = "logout";
    Operation["SignTransactions"] = "signTransactions";
    Operation["SignMessage"] = "signMessage";
    Operation["CancelAction"] = "cancelAction";
})(Operation = exports.Operation || (exports.Operation = {}));
